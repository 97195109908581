import React, { useState } from 'react';
import { ModalDialog } from 'bu.components-customer-apps';

import Siegel from '../../../svgs/tuev-check24-2024.svg';

export default function TuevLogo() {
  const [isOpen, setIsOpen] = useState(false);
  const renderModalHeader = () => <div className="TuevLogo-popupHeadline">TÜV-geprüfte Kundenzufriedenheit</div>;
  return (
    <React.Fragment>
      <div className="TuevLogo" onClick={() => setIsOpen(true)}>
        <Siegel className="TuevLogo-svg"/>
      </div>
      <ModalDialog.Mobile
        header={renderModalHeader()}
        onModalClose={() => setIsOpen(false)} isOpen={isOpen} >
        <div className="TuevLogo-popupContent">
          <div>
            CHECK24 ist durch TÜV Saarland in 2024 bereits das achte Mal in Folge mit der Bestnote „sehr gut“
            ausgezeichnet worden. Neben einem „sehr gut“ im Bereich „Gesamtzufriedenheit Unternehmen“
            erreicht CHECK24 eine Weiterempfehlungsbereitschaft seiner Kunden von 96,1 Prozent. 
            Besonders hervorgehoben wurde von den Kunden der exzellente Kundenservice von CHECK24.
          </div>
          <div className="TuevLogo-popupAddressHeadline">
            Das „TÜV Kundenzufriedenheit“-Siegel wurde erteilt von:
          </div>
          <div>
            TÜV Saarland Holding GmbH<br />
            Am TÜV 1<br />
            66280 Sulzbach<br />
            <a href="http://www.service-tested.de" target="_blank"
              rel="noopener noreferrer">www.service-tested.de
            </a>
          </div>
        </div>
      </ModalDialog.Mobile>
    </React.Fragment>
  );
}
