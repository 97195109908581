import React from 'react';
import EkomiLogo from '../../common/ekomi-logo/ekomi-logo';
import TuevLogo from '../../common/tuev-logo/tuev-logo';

function TrustLogos() {
  return (
    <div className="TrustLogos-logoContainer">
      <EkomiLogo/>
      <TuevLogo/>
    </div>
  );
}

export default TrustLogos;
