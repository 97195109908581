import React, { useState } from 'react';
import { ModalDialog } from 'bu.components-customer-apps';

import EkomiSvg from '../../../svgs/ekomi.svg';

export default function EkomiLogo() {
  const [isOpen, setIsOpen] = useState(false);
  const renderModalHeader = () => (
    <div className="EkomiLogo-popupHeadline">eKomi Kundenauszeichnung: Versicherungsvergleich</div>
  );
  return (
    <React.Fragment>
      <div className="EkomiLogo" onClick={() => setIsOpen(true)}>
        <EkomiSvg className="EkomiLogo-svg" />
      </div>
      <ModalDialog.Mobile
        header={renderModalHeader()}
        onModalClose={() => setIsOpen(false)} isOpen={isOpen} >
        <div className="EkomiLogo-popupContent">
          <div className="EkomiLogo-popupSubHeadline">Zertifizierung für Kundenzufriedenheit</div>
          <div className="EkomiLogo-contentPart">
            Über den unabhängigen Bewertungsdienstleister eKomi können Kunden den Service von
            CHECK24 mit einem bis fünf Sternen bewerten.
          </div>
          <div className="EkomiLogo-contentPart">
            Der Versicherungsvergleich von CHECK24 und der
            Kundenservice werden im Gesamtschnitt als sehr gut eingeschätzt. Durch die transparente
            Einbindung auf unserer Seite können sich Interessierte die Einschätzungen anderer
            Verbraucher ansehen und sich so selbst ein objektives Bild machen.
          </div>
          <div className="EkomiLogo-popupAddressHeadline">
            Das &quot;eKomi Kundenauszeichnung&quot;-Siegel wurde erteilt von:
          </div>
          <div>
            eKomi Ltd<br />
            Markgrafenstr. 11<br />
            10969 Berlin<br />
            <a href="http://www.ekomi.de" target="_blank"
              rel="noopener noreferrer">www.ekomi.de
            </a>
          </div>
        </div>
      </ModalDialog.Mobile>
    </React.Fragment>
  );
}
